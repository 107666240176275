.link {
    text-decoration: none;
    color: inherit;
    line-height: 0;
}

.textLink {
    display: inline-block;
    color: inherit;
    text-decoration: underline;
}

.text {
    text-align: center;
}
