
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  gap: 0;
  background: none;
  /* disable highlight on mobile devices when tapping on screen */
  -webkit-tap-highlight-color: transparent;
}
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  gap: 0;
  background: none;
  /* disable highlight on mobile devices when tapping on screen */
  -webkit-tap-highlight-color: transparent;
}
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  gap: 0;
  background: none;
  /* disable highlight on mobile devices when tapping on screen */
  -webkit-tap-highlight-color: transparent;
}

body {
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
   sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
 }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

a {
  display: contents;
  text-decoration: none;
}